<template>
  <div class="wap-footer">
    <div class="footer-main">
      <div class="top-link">
        <div class="left">
          <div class="icon flex-center-start">
            <img :src="logo || logoUrl" alt="" />
            <span class="app-name">{{ appName }}</span>
          </div>
          <div class="desc">
            {{ $t('huo-de-nin-geng-duo-de-you-hui-quan') }}
          </div>
          <div class="input">
            <el-input
              v-model="keyword"
              :placeholder="$t('nin-de-dian-zi-you-xiang')"
            ></el-input>
          </div>
          <div class="btn">
            <el-button type="primary" plain>{{ $t('ding-yue') }}</el-button>
          </div>
        </div>
        <div class="right flex-wrap">
          <div class="link-list">
            <div class="title">{{ $t('ke-hu-fu-wu') }}</div>
            <div
              class="link"
              @click="toPath(item.path)"
              v-for="(item, i) in menuList1"
              :key="i"
            >
              <span v-if="!item.tooltip">{{ item.name }}</span>
              <el-tooltip v-else :content="item.tooltip" placement="left">
                <span>{{ item.name }}</span>
              </el-tooltip>
            </div>
          </div>
          <div class="link-list">
            <div class="title">{{ $t('tui-huo-he-huan-huo') }}</div>
            <div
              class="link"
              v-for="(item, i) in menuList2"
              :key="i"
              @click="toPath(item.path)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="link-list">
            <div class="title">{{ $t('yong-hu-zhong-xin') }}</div>
            <div
              class="link"
              v-for="(item, i) in menuList3"
              :key="i"
              @click="toPath(item.path, true)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="link-list">
            <div class="title">{{ $t('guan-yu-wo-men') }}</div>
            <div
              class="link"
              v-for="(item, i) in menuList4"
              :key="i"
              @click="toPath(item.path)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="">
          <div class="pay-box">
            <div class="title">{{ $t('zhi-fu-fang-shi') }}</div>
            <div class="pay-list">
              <div
                class="pay flex-column-center"
                v-for="(item, i) in payList"
                :key="i"
              >
                <img :src="item.icon" alt="" />
                <div class="label">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div class="desc">
            <div class="title">{{ appName }}</div>
            <div class="info">{{ appName }} {{ $t('quan-qiu-zhan') }}</div>
          </div>
          <div class="logo">
            <img :src="logoUrl2" alt="" />
          </div>
        </div>

        <div class="app-list flex-center">
          <div class="app" v-for="(item, i) in appList" :key="i">
            <img :src="item.icon" alt="" />
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="info" v-show="false">{{ $t('ban-quan', { appName: appName }) }}</div>
        <div class="desc">
          © 2024 CrownCommerce LLC. All rights reserved.
          Registered Address: 1942 Broadway St STE 314C, Boulder, CO 80302, US
          Entity ID: 20241644012 | Registered in Colorado
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getBaseUrl } from '@/utils/common'
export default {
  data() {
    return {
      logoUrl: require('@/assets/imgs/logo.png'),
      logoUrl2: require('@/assets/imgs/logo4.png'),
      keyword: '',
      payList: [
        {
          icon: require('@/assets/imgs/pay1.png'),
          name: 'Binance'
        },
        {
          icon: require('@/assets/imgs/pay2.png'),
          name: 'Huobi'
        },
        {
          icon: require('@/assets/imgs/pay3.png'),
          name: 'OKX'
        },
        {
          icon: require('@/assets/imgs/pay4.png'),
          name: 'KraKen'
        },
        {
          icon: require('@/assets/imgs/pay5.png'),
          name: 'Coinbase'
        },
        {
          icon: require('@/assets/imgs/pay6.png'),
          name: 'MetaMask'
        },
        {
          icon: require('@/assets/imgs/pay7.png'),
          name: 'KuCoin'
        },
        {
          icon: require('@/assets/imgs/pay8.png'),
          name: 'Bitfinex'
        }
      ],
      appList: [
        {
          icon: require('@/assets/imgs/app1.png')
        },
        {
          icon: require('@/assets/imgs/app2.png')
        },
        {
          icon: require('@/assets/imgs/app3.png')
        },
        {
          icon: require('@/assets/imgs/app4.png')
        },
        {
          icon: require('@/assets/imgs/app5.png')
        }
      ]
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo
    },
    appName() {
      return this.$store.state.appName
    },
    menuList1() {
      return [
        {
          name: this.$t('zai-xian-ke-fu'),
          path: 'wapKefu'
        },
        {
          name: this.$t('lian-xi-wo-men'),
          path: '',
          tooltip: 'Contact email: support@huanyaus.com'
        },
        {
          name: this.$t('app-xia-zai-mai-jia-duan'),
          path: 'download'
        },
        {
          name: this.$t('app-xia-zai-shang-jia-duan'),
          path: 'download'
        }
      ]
    },
    menuList2() {
      return [
        {
          name: this.$t('yin-si-zheng-ce'),
          path: 'wapPrivacy'
        },
        {
          name: this.$t('tui-huo-zheng-ce'),
          path: 'wapRefundRole'
        },
        {
          name: this.$t('song-huo-ji-qu-huo'),
          path: 'wapDelivery'
        },
        {
          name: this.$t('mai-jia-zheng-ce'),
          path: 'wapShippingPolicy'
        }
      ]
    },
    menuList3() {
      return [
        {
          name: this.$t('yong-hu-zhu-ce'),
          path: 'wapUserRegister'
        },
        {
          name: this.$t('ding-dan-cha-xun'),
          path: 'wapOrder'
        },
        {
          name: this.$t('shang-pin-shou-cang'),
          path: 'wapCollect'
        },
        {
          name: this.$t('wo-de-qian-bao'),
          path: 'wapMy'
        }
      ]
    },
    menuList4() {
      return [
        {
          name: this.$t('guan-yu-wo-men'),
          path: 'wapAbout'
        },
        {
          name: this.$t('zhao-xian-na-shi'),
          path: ''
        },
        {
          name: this.$t('xin-wen-zi-xun'),
          path: ''
        }
      ]
    },
    isLogin() {
      return this.$store.state.isLogin
    }
  },
  methods: {
    getBaseUrl,
    toPath(path, checkLogin) {
      if (!path) {
        return
      }
      if (checkLogin && !this.isLogin) {
        this.$router.push({
          name: 'userLogin'
        })
        return
      }
      this.$router.push({
        name: path
      })
    }
  }
}
</script>